/*
 * @Description: 
 * @Author: wangwenshuai
 * @Date: 2022-12-15 22:37:59
 * @LastEditors: wangws
 * @LastEditTime: 2024-07-27 15:35:52
 */
import { service } from '@/utils/service'

// export const getRank = () => { return service({ url: '/vote/rank', method: 'get' })}

//生成大纲
export const generateOutline = data => {
    return service({ url: "/api/paper/generateOutline", method: "post", data });
};

//重新生成大纲
export const reGenerateOutline = data => {
    return service({ url: "/api/paper/reGenerateOutline", method: "post", data });
};
//生成全文
export const generatePaper = data => {
    data.ext3 = "gm";
    return service({ url: "/api/paper/generatePaper", method: "post", data });
};

//重新生成全文
// export const reGeneratePaper = data => {
//     return service({ url: "/api/paper/reGeneratePaper", method: "post", data });
// };

//根据用户名查询查询论文
export const getByUserName = (userName) => { return service({ url: `/api/paper/getByUserName/${userName}`, method: 'get'})}

//根据论文ID查询大纲
export const listOutlineByPaper = (id) => { return service({ url: `/api/paper/listOutlineByPaper/${id}`, method: 'get'})}

//立即支付
export const toPaperPay = (id) => { return service({ url: `/api/paper/toPaperPay/${id}`, method: 'get'})}

//根据订单号查询论文
export const getPaperByBill = (billNo) => { return service({ url: `/api/paper/getPaperByBill/${billNo}`, method: 'get'})}

//aigc订单
export const reduceAcgiBill = (userName) => {
    return service({ url: `/api/paper/reduce/acgi/bill/${userName}?t=${new Date().getTime()}`, method: 'get'})}

//降低aigc率 订单
export const reduceAcgiRate = data => {
    data.ext1 = "gm";
    return service({ url: "/api/paper/reduce/acgi/rate", method: "post", data });
};

//降低aigc率
export const reduceAcgi = (id) => { return service({ url: `/api/paper/acgi/${id}`, method: 'get'})}

//论文检测
export const paperCheck = data => {
    data.ext3 = "gm";
    return service({ url: "/api/paper/check", method: "post", data });
};
//刷新二维码
export const checkRefresh = (id) => { return service({ url: `/api/paper/checkrefresh/${id}`, method: 'get'})}

//根据订单号查检测记录
export const checkBillNo = (billNo) => { return service({ url: `/api/paper/check/billNo/${billNo}`, method: 'get'})}

//删除报告
export const delCheckReport = (id) => { return service({ url: `/api/paper/check/del/${id}`, method: 'get'})}
